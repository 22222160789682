import React from "react"
import { Col, Row } from "antd"
import { graphql } from "gatsby"
import Shell from "../components/Shell/Shell"
import SEO from "@avldev/gatsby-theme-core-ui/src/components/SEO"
import Hero from "../components/SharpHero/SharpHero"
import BookingBar from "../components/BookingBar/BookingBar"
import Form from "../components/Forms/GeneralContact"
import ManagerInfo from "../components/ManagerInfo/ManagerInfo"
import BlogReel from "../components/BlogReel/BlogReel"

const description = `Contact us today for more information about The Residences at Biltmore or your vacation to Asheville, North Carolina! Our all suite hotel is the perfect vacation destination.`

const rowProps = {
  align: "middle",
  justify: "center",
  type: "flex",
  style: {
    margin: `24px auto`,
    maxWidth: 1360,
  },
}

const detailsCol = {
  xs: 23,
  md: 10,
  lg: 9,
  style: {
    padding: 12,
    textAlign: `center`,
  },
}

const formColProps = {
  xs: {
    span: 23,
  },
  md: 10,
  lg: 9,
  style: {
    padding: 12,
  },
}

const formProps = id => {
  return {
    formName: "General Contact",
    automationId: id,
    gtmClickId: "contact-page-form-button",
  }
}

export default ({ data, location }) => {
  const { hero, hotel } = data

  const heroProps = {
    caption: `Get In Touch`,
    contentfulAsset: hero,
    height: `30vh`,
    imageStyle: { filter: `brightness(0.65)` },
  }

  const managerInfoProps = {
    propertyName: `The Residences at Biltmore`,
    description: hotel.description.description,
    manager: {
      name: `Southern Resort Group`,
      url: `https://southernresortgroup.com`,
      streetNumber: `18 Regent Blvd., Suite A`,
      city: `Asheville`,
      state: `NC`,
      zip: `28806`,
    },
    phone1: {
      heading: `24hr Concierge`,
      number: `8283508000`,
    },
    phone2: {
      heading: `Reservations`,
      number: hotel.phone,
    },
  }

  return (
    <Shell>
      <SEO
        meta={{
          description,
          image: hero.fluid.src,
          imageAlt: hero.description,
        }}
        title="Contact Us - The Residences at Biltmore"
        url={location.href}
      />
      <Hero {...heroProps} />
      <BookingBar />
      <Row {...rowProps}>
        <Col {...detailsCol}>
          <ManagerInfo {...managerInfoProps} />
        </Col>
        <Col {...formColProps}>
          <Form {...formProps(37)} />
        </Col>
      </Row>
      <BlogReel numPosts={3} />
    </Shell>
  )
}

export const query = graphql`
  query {
    hero: contentfulAsset(title: { eq: "couplebyfirepitFeaturedImage" }) {
      ...HeroFluid
    }
    hotel: contentfulHotel(name: { eq: "Residences at Biltmore" }) {
      description {
        description
      }
      phone
    }
  }
`
