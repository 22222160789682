import React from "react"
import { Col, Row, Typography } from "antd"
import {
  clickablePhoneNumber,
  prettifyPhoneNumber,
} from "../../utils/phoneNumbers"
import style from "./ManagerInfo.module.scss"

const ManagerInfo = props => {
  const titleProps = {
    level: 4,
    style: {
      textTransform: `uppercase`,
    },
  }

  const descriptionProps = {
    style: {
      marginBottom: 24,
      marginTop: 24,
    },
  }

  const phoneRowProps = {
    align: `top`,
    justify: `center`,
    type: `flex`,
    style: {
      marginTop: 32,
    },
  }

  const phoneColProps = {
    className: style.phoneCol,
    xs: 24,
    md: 11,
  }

  const { propertyName, description, manager, phone1, phone2 } = props

  return (
    <div className={style.wrapper}>
      <Typography.Title {...titleProps}>{propertyName}</Typography.Title>
      <Typography.Text>
        By <a href={manager.url}>{manager.name}</a>
      </Typography.Text>
      <Typography.Paragraph {...descriptionProps}>
        {description}
      </Typography.Paragraph>
      <Typography.Paragraph strong>
        {manager.name}
        <br />
        {manager.streetNumber}
        <br />
        {manager.city}, {manager.state} {manager.zip}
      </Typography.Paragraph>
      <Row {...phoneRowProps}>
        <Col {...phoneColProps}>
          <Typography.Text>{phone1.heading}</Typography.Text>
          <br />
          <Typography.Text strong>
            <a href={`tel:${clickablePhoneNumber(phone1.number)}`}>
              {prettifyPhoneNumber(phone1.number)}
            </a>
          </Typography.Text>
        </Col>
        <Col {...phoneColProps}>
          <Typography.Text>{phone2.heading}</Typography.Text>
          <br />
          <Typography.Text strong>
            <a href={`tel:${clickablePhoneNumber(phone2.number)}`}>
              {prettifyPhoneNumber(phone2.number)}
            </a>
          </Typography.Text>
        </Col>
      </Row>
    </div>
  )
}

export default ManagerInfo
